import React, { useState, useRef, useEffect ,useMemo} from 'react';
//import { useHover } from '@react-hooks-library/core'
//import olio from "../../../static/videoPoster.jpg" // Tell webpack this JS file uses this image
import videoSrc from "../../../static/Simone_1.mp4" // Tell webpack this JS file uses this image
import { PlayerStyle } from "./PlayerStyle"
import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { BsPlayCircle  as Play} from 'react-icons/bs';
import { BsPauseCircle as Pause } from 'react-icons/bs';
//import useHover from 'react-use-hover';


const Player = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    const breakpoints = useBreakpoint();
    let flagPosterImage="";

    const firstRender = useMemo(
        () => flagPosterImage="overlayOnly",
        [isPlaying]
    );


   

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
       
        setIsPlaying(!isPlaying);
    };

    return (
        <PlayerStyle>
            <div className={isPlaying ? "isPlaying" : "isPause"}>
                <video
                    ref={videoRef}
                    width="100%"
                    height="auto"
                    controls
                //poster="https://unsplash.com/it/foto/GcBKr7Nk60w"
                /*  autoPlay 
                 muted */
                >
                    <source src={videoSrc} type="video/mp4" />
                </video>

                <div className={"posterImageHolder "+flagPosterImage }>

                    {breakpoints.sm ? (
                        <StaticImage
                            className="posterImg"
                            src="../../../static/videoPoster.jpg"
                            alt="Perks Module"
                            layout="constrained"
                            placeholder="blurred"
                        />
                    ) : (
                            <StaticImage
                                className="posterImg"
                                src="../../../static/videoPoster.jpg"
                                alt="Perks Module"
                                layout="constrained"
                                placeholder="blurred"
                            />
                        )
                    }
                    <div className="overlay">
                    </div>
                </div>
                <div className="controls-player"  >
                    {<button className={isPlaying ? "active" : "inactive"} onClick={togglePlay}>
                        {isPlaying ? <Pause /> : <Play />}

                    </button>}

                </div>
            </div>
        </PlayerStyle>
    )
}

export default Player;