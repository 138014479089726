import * as React from "react"
import { ListaServiziStyles } from "./ListaServiziStyles"
import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Lista from "./Lista"

const ListaServizi = ({ content }) => {

    const breakpoints = useBreakpoint();
    console.log("content", content)
    return (
        <ListaServiziStyles className="section mb-5">

            <div className="container">
                <div className="row ">
                    {

                        content && content.servizi.map((el, index) => {

                            return (
                                <div className="col-12 col-lg-6 mb-5 px-0 px-md-5" key={index}>
                                    <Lista content={el} index={index} ></Lista>
                                </div>
                            );


                        })


                    }
                </div>
            </div>

        </ListaServiziStyles>
    )
}

export default ListaServizi
