import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import RichText from "../components/RichText"
import ModuloSection from "../components/ModuloSection/ModuloSection"
import ListaServizi from "../components/ListaServizi/ListaServizi"
import Player from "../components/Player/Player"
import { StaticImage } from "gatsby-plugin-image";
//import { useBreakpoint } from 'gatsby-plugin-breakpoints';



const DefaultTemplate = contentfulPage => {
  const headerImage = getImage(contentfulPage.headerImage)
  return (
    <div className={contentfulPage.idPage + " mainLayout DefaultTemplate"}>
      <Seo title={contentfulPage.title} />
      <Layout>
        <SimpleBanner title={contentfulPage.title} introduction={contentfulPage.introduction} slogan={contentfulPage.slogan}>
          <GatsbyImage alt="banner" className="banner__image" image={headerImage} />
        </SimpleBanner>
        {/* <ModuloSection>
          <RichText richText={contentfulPage.mainContent} />
        </ModuloSection> */}

        <ListaServizi content={contentfulPage.elementoCustom} />


        <div className="section">
          <div className="row_2_cols">
            <div >
              <RichText classes="textOnWhite" richText={contentfulPage.mainContent} />
            </div>
            <div>
              <RichText classes="textOnWhite" richText={contentfulPage.secondContent} />
            </div>
          </div>
        </div>
        <div className="flex  backgroundColorMain">
          <div className="section ">

            <div className="row_2_cols">
              <div className="foto">
                <StaticImage
                  className="img"
                  src="../../static/giulia.jpg"
                  alt="Giulia"
                  layout="constrained"
                  placeholder="blurred"
                />
              </div>
              <div className="testo">
                <RichText classes="" richText={contentfulPage.thirdContent} />
              </div>

            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default DefaultTemplate