import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ModuloSectionStyles } from "./ModuloSectionStyles"
import { MdOutlineClose as Cross } from "react-icons/md"

import Professionalita from '../../../static/professionalita.svg'
import Button from "../Button/Button"
import { Link, navigate } from "gatsby"
//import { useMediaQuery } from 'react-responsive'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const ModuloSection = ({ children }) => {
  
  const breakpoints = useBreakpoint();
  return (
    <ModuloSectionStyles >

      {breakpoints.sm ? (
        <StaticImage
          className="perks__image--bg "
          src="../../../static/macchinario_mobile.jpg"
          alt="Perks Module"
          layout="constrained"
          placeholder="blurred"
        />
      ) : (
          <StaticImage
            className="perks__image--bg"
            src="../../../static/olio_img_1.jpg"
            alt="Perks Module"
            layout="constrained"
            placeholder="blurred"
          />
        )
      }


      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <div className="section section__padding">
        {children}
        </div>
      </div>
    </ModuloSectionStyles>
  )
}

export default ModuloSection
