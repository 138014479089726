import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import RichText from "../components/RichText"
import ModuloSection from "../components/ModuloSection/ModuloSection"
import ListaServizi from "../components/ListaServizi/ListaServizi"
import Player from "../components/Player/Player"
import { StaticImage } from "gatsby-plugin-image";
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"

//import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import "../styles/bootstrap.scss"


const ServiziTemplate = contentfulPage => {
    const headerImage = getImage(contentfulPage.headerImage)
    return (
        <div className={contentfulPage.idPage + " mainLayout ServiziTemplate"}>
            <Seo title={contentfulPage.title} />
            <Layout>
                <SimpleBanner title={contentfulPage.title} introduction={contentfulPage.introduction} slogan={contentfulPage.slogan}>
                    <GatsbyImage alt="banner" className="banner__image" image={headerImage} />
                </SimpleBanner>


                <ListaServizi content={contentfulPage.elementoCustom} />


                <section className="section mt-5">
                    <aside className="text-primary w-50 m-auto">
                        <h3 className="fw-semibold fs-2 text-center border-bottom border-top border-primary ">Richiedi il tuo <span className="strongText">preventivo personalizzato e gratuito!</span></h3>
                        <a className="text-primary btn w-100 fs-4" href="/contatti">Clicca qui<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" ><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path></svg>
                        </a>
                    </aside>
                </section>

            </Layout>
        </div>
    )
}

export default ServiziTemplate