import styled from "styled-components"

export const PlayerStyle = styled.div`
    position:relative;
   
    video {
        border: 4px solid var(--primary);
        border-radius: 10px;
        overflow: hidden;
    }
    
   
  
    @media (min-width: 1024px) {
        margin-left:10%;
    }

    

    .isPlaying {
        .posterImageHolder {
            .posterImg , .overlay{
                display:none;
            }
        }

        
        
    }
    .isPause {
        .posterImageHolder {
            .posterImg , .overlay{
                display:block;
            }
        }
        .posterImageHolder.overlayOnly {
             .overlay{
                display:block;
            }
            .posterImg {
                display:none;
            }
        }
    }
    .posterImg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        border: 4px solid var(--primary);
        border-radius: 10px;
        overflow: hidden;
    }

    .controls-player {
        position: absolute;
        z-index: 9;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        :hover button.active{
            display:block;
        }
        
    }

    .posterImageHolder {
        .overlay {
            background: var(--primary);
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            left: 0;
            opacity:0.3;
        }
    }

    

    .controls-player button {
        font-size: 7rem;
        background: transparent;
        border: none;
        cursor: pointer;
        color:#fff;
    }

    button.active {
        display: none;
    }

    

    
`
