import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import RichText from "../components/RichText"
import ModuloSection from "../components/ModuloSection/ModuloSection"
import Player from "../components/Player/Player"
import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';



const OlioTemplate = contentfulPage => {
  const breakpoints = useBreakpoint();

  const headerImage = getImage(contentfulPage.headerImage)
  const headerImageMobile = getImage(contentfulPage.headerImageMobile)

  return (
    <div className={contentfulPage.idPage + " mainLayout OlioTemplate"}>
      <Seo title={contentfulPage.title} />
      <Layout>
        <SimpleBanner classes="paginaOlio" title={contentfulPage.title} introduction={contentfulPage.introduction} slogan={contentfulPage.slogan}>
          {breakpoints.sm ? (
            <GatsbyImage alt="banner" className="banner__image" image={headerImageMobile} />
          ) : (
              <GatsbyImage alt="banner" className="banner__image" image={headerImage} />
            )
          }

        </SimpleBanner>
        <ModuloSection>
          <RichText richText={contentfulPage.mainContent} />
        </ModuloSection>

        <div className="section">
          <div className="row_2_cols">
            <div >
              <RichText classes="textOnWhite" richText={contentfulPage.secondContent} />
            </div>
            <div>
              <Player />
            </div>
          </div>
        </div>
        <div className="flex  backgroundColorMain">
          <div className="section ">

            <div className="row_2_cols">
              <div className="foto">
                <StaticImage
                  className="img"
                  src="../../static/giulia.jpg"
                  alt="Giulia"
                  layout="constrained"
                  placeholder="blurred"
                />
              </div>
              <div className="testo">
                <RichText classes="" richText={contentfulPage.thirdContent} />
              </div>

            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default OlioTemplate