import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import RichText from "../components/RichText"
import ModuloSection from "../components/ModuloSection/ModuloSection"
import ListaServizi from "../components/ListaServizi/ListaServizi"
import Player from "../components/Player/Player"
import { StaticImage } from "gatsby-plugin-image";
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import ProductFeed from "../components/Feeds/ProductFeed"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
//import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import "../styles/bootstrap.scss"

import { AiOutlineClockCircle as Clock } from "react-icons/ai"



const AziendaTemplate = contentfulPage => {
    const headerImageMobile = getImage(contentfulPage.headerImage)
    const headerImage = getImage(contentfulPage.headerImageMobile)

    const position = [51.505, -0.09]
    const breakpoints = useBreakpoint();
    return (
        <div className={contentfulPage.idPage + " mainLayout ServiziTemplate"}>
            <Seo title={contentfulPage.title} />
            <Layout>
                <SimpleBanner classes="paginaAzienda" title={contentfulPage.title} introduction={contentfulPage.introduction} slogan={contentfulPage.slogan}>

                    {breakpoints.sm ? (
                        <GatsbyImage alt="banner" className="banner__image" image={headerImage} />
                    ) : (
                            <GatsbyImage alt="banner" className="banner__image" image={headerImageMobile} />
                        )
                    }
                </SimpleBanner>

                <div className="flex  backgroundColorMain border-top">
                    <div className="section ">

                        <div className="row_2_cols azienda fs-4">
                            <div className="foto">
                                <StaticImage
                                    className="img"
                                    src="../../static/simoneportrait.jpg"
                                    alt="Giulia"
                                    layout="constrained"
                                    placeholder="blurred"
                                />
                            </div>
                            <div className="testo">
                                <RichText classes="" richText={contentfulPage.mainContent} />
                            </div>

                        </div>
                    </div>
                </div>
                {/* <ListaServizi content={contentfulPage.elementoCustom} />
                

                <section className="section mt-5">
                    <aside className="text-primary w-50 m-auto">
                        <h3 className="fw-semibold fs-2 text-center border-bottom border-top border-primary ">Richiedi il tuo <span className="strongText">preventivo personalizzato e gratuito!</span></h3>
                        <a className="text-primary btn w-100 fs-4" href="/contatti">Clicca qui<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" ><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path></svg>
                        </a>
                    </aside>
                </section> */}

                {/*   <Features
                    title="Ognuno mette qualcosa di proprio e la voglia di realizzare se stesso attraverso il lavoro"
                    introduction="Questo il vero segreto del nostro successo!"
                /> */}

                <div className="section">
                    <div className="row_2_cols align-items-center">
                        <div className="mt-5">
                            <RichText classes="textOnWhite" richText={contentfulPage.secondContent} />
                        </div>
                        <div className="flex flex-column align-items-center">

                            <div className="d-flex align-items-center border-bottom mb-2">
                                <div className="icon fs-2 text-primary me-2"><Clock className="text-primary" /> </div> <h2 className="text-primary mb-0">Visitateci!</h2>
                            </div>
                            <h6 className="text-primary text-center">
                                Lunedì - Venerdi : <b>08:00/12:00 - 13:30/18:00</b>

                            </h6>

                            <h6 className="text-primary text-center">
                                Sabato: <b>09:00/1200</b>

                            </h6>




                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="feed"><ProductFeed {...contentfulPage} /></div>
                </div>
            </Layout>
        </div>
    )
}

export default AziendaTemplate