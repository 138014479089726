import * as React from "react"
import Servizi_lavori from '../../../static/servizi_lavori.svg'
import Servizi_manutenzione from '../../../static/servizi_manutenzione.svg'
import Servizi_consulenza from '../../../static/servizi_consulenza.svg'

import Irrigazione from '../../../static/irrigazione.svg'
import Potatura from '../../../static/potatura.svg'
import Recinto from '../../../static/recinto.svg'
import Progettazione_alt from '../../../static/progettazione_alt.svg'
import Trattamenti from '../../../static/trattamenti.svg'

const Lista = ({ content, index }) => {
    const icons = [<Servizi_lavori />, <Servizi_consulenza />, <Servizi_manutenzione />]
    const icons_alt = [<Progettazione_alt />, <Irrigazione />, <Recinto />,<Trattamenti />,<Potatura />]
    return (
        <>
            {content.tipo == 1 ? (
                <>
                    <div className="lista-header d-flex flex-grow-1 align-items-end border-bottom border-primary pb-2">
                        <div className="icon me-3">{icons[index]} </div>
                        <div className="d-flex flex-column">
                            <span className="fs-1 lh-sm text-primary fw-bold">{content.label.main}</span>
                            <span className="fs-4 lh-sm text-primary">{content.label.sub}</span>
                        </div>
                    </div>
                    <ul className="mt-2">
                        {content.lista.map((el, index) => {
                            return <li key={index} className="text-primary fs-5">{el}</li>
                        })}
                    </ul>
                </>
            ) : (
                content.elenco.map((el, index) => {
                     
                    return   <div key={index} className="lista-header elenco d-flex flex-grow-1 align-items-end border-bottom border-primary pb-2 mb-2">
                            {<div className="icon-alt me-3">{icons_alt[index]} </div>}
                            <div className="d-flex flex-row align-items-center">
                                <span className="fs-2 lh-sm text-primary fw-bold">{el.label.main}</span>
                                <span className="fs-5 lh-sm text-primary ms-2">{el.label.sub}</span>
                            </div>
                        </div>
                    
                })
                )
            }


        </>
    )
}

export default Lista


