import React from "react"
import { graphql } from "gatsby"
import DefaultTemplate from "../templates/default-template"
import FeedTemplate from "../templates/feed-template"
import OlioTemplate from "../templates/olio-template"
import ServiziTemplate from "../templates/servizi-template"
import AziendaTemplate from "../templates/azienda-template"


const getTemplate = (contentfulPage) => {
  const { template } = contentfulPage
  //console.log("...contentfulPage",contentfulPage)

  switch (template) {

    case "feed":
      return <FeedTemplate {...contentfulPage} />

    case "olio":
      return <OlioTemplate {...contentfulPage} />

    case "servizi":
      return <ServiziTemplate {...contentfulPage} />

    case "azienda":
      return <AziendaTemplate {...contentfulPage} />

    default:
      return <DefaultTemplate {...contentfulPage} />
  }
}

const Page = ({ data: { contentfulPage } }) => {
  return <><main>{getTemplate(contentfulPage)}</main><div id='video-root'></div></>
}

export const data = graphql`
  query pageQuery($id: String) {
    contentfulPage(id: { eq: $id }) {
      template
      title
      idPage
      introduction
      slogan,
      elementoCustom {
        servizi {
          lista
          tipo
          label {
            main
            sub
          }
          elenco {
            label {
              main
              sub
            }
          }
        }
      }
      headerImage {
        gatsbyImageData(
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      headerImageMobile {
        gatsbyImageData(
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      
      mainContent {
        raw
      }
      secondContent {
        raw
      }
      thirdContent {
        raw
      }
    }
  }
`

export default Page
