import styled from "styled-components"

export const ListaServiziStyles = styled.section`
  
.lista-header  {
    height:100px;
    .icon {
        width:80px
    }
    
}
.lista-header.elenco {
    height:70px;
    
    .icon-alt {
        width:50px
    }
}
`
